/*eslint-disable*/
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import WellTree from "containers/Home/components/WellTree";
import MenuCard from "./components/MenuCard";
import AnalysisCardContents from "./components/AnalysisCardContents";
import "./styles.css";

const StartMenu = observer(() => {

  const { userStore } = useStore();

  const isAdmin = userStore.isAdmin;
  const fetchIsAdmin = userStore.fetchIsAdmin;
  const [isTreemapVisible, setIsTreemapVisible] = useState(false)

  useEffect(() => {
    if (fetchIsAdmin.initial) {
      fetchIsAdmin();
    }
  }, [])

  const navigate = useNavigate();
  const cards = [
    {
      content: <AnalysisCardContents />,
      title: "Анализ скважин",
      route: "/analysis",
      isVisible: () => true,
    },
    {
      content: <AnalysisCardContents />,
      title: "Панель администрирования",
      route: "/admin",
      isVisible: () => isAdmin,
    },
    {
      content: <AnalysisCardContents />,
      title: "Анализ отклонений",
      route: "/deviationAnalysis",
      isVisible: () => true,
    },
    {
      content: <AnalysisCardContents />,
      title: "Treemap",
      route: "/treemap",
      isVisible: () => isTreemapVisible,
    },
  ];

  const handleChangeTreemapVisible = (event) => {
    const { key, ctrlKey, altKey } = event;
    if (ctrlKey && [" "].includes(key) && altKey) {
      setIsTreemapVisible(!isTreemapVisible)
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleChangeTreemapVisible);
    return () => {
      window.removeEventListener("keydown", handleChangeTreemapVisible);
    };
  }, [handleChangeTreemapVisible]);

  const handleClick = (card) => {
    // if (card.route === "/admin") return;

    return () => {
      navigate(card.route);
    };
  };

  return (
    <div className="tile-background">
      <WellTree showWells={false} />
      <div className="services-list">
        {fetchIsAdmin.resolved && cards
          .filter((card) => card.isVisible())
          .map((c) => (
            <div key={c.title}>
              <MenuCard
                title={c.title}
                contents={c.content}
                clickHandler={handleClick(c)}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default StartMenu;
