/*eslint-disable*/
import { useEffect, useContext, createContext, useState } from "react";
import { notification } from "antd";
import cookie from "js-cookie";
import decode from "jwt-decode";
import request from "utils/request";
import useUserInfo from "./useUserInfo";
import useTranslations from "./useTranslations";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState();
  const [isAuth, setIsAuth] = useState(false)

  useTranslations(user?.uid);
  const { clear: clearUserInfo } = useUserInfo(user?.uid);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("AUTH_PROVIDER")) !== "krb") loadUserFromCookies();
  }, []);

  const showUnexpectedErrorNotification = () => {
    notification.error({
      message: "Неизвестная ошибка!",
      description: "Произошла неизвестная ошибка при попытке войти в систему CycleOp.",
      duration: 0,
      placement: "center",
    });
  }

  const showUserNotExistErrorNotification = (userName) => {
    notification.error({
      message: `Учетная запись пользователя ${userName ? userName : ""} не найдена в системе!`,
      description: "Для предоставления доступа обратитесь к администратору.",
      duration: 0,
      placement: "center",
    });
  }

  const loadUserFromCookies = async () => {
    try {
      const refreshToken = cookie.get("refreshToken");
      const accessToken = cookie.get("accessToken");

      if (
        (accessToken &&
          decode(accessToken).exp * 1000 - new Date().getTime() < 180000) ||
        (!accessToken && refreshToken)
      ) {
        request.defaults.headers.common.Authorization = refreshToken;
        const {
          request: { response },
        } = await request.post("/auth/refresh");

        setTokens(response);
      } else if (accessToken) {
        const decodedUser = decode(accessToken);
        request.defaults.headers.common.Authorization = accessToken;

        setUser({
          ...decodedUser,
        });
        setTimeout(
          loadUserFromCookies,
          new Date(decodedUser.exp * 1000).getTime() -
          new Date().getTime() -
          180000
        );
      } else if (!accessToken && !refreshToken) {
        logout();
      }
    } catch (error) {
      logout();
    }
  };

  const logout = () => {
    if (JSON.parse(localStorage.getItem("AUTH_PROVIDER")) === "krb") {
      setIsAuth(false)
      cookie.remove("NSC_TMAS", { domain: '.lukoil.com' });
    }

    if (JSON.parse(localStorage.getItem("AUTH_PROVIDER")) === "jwt") {
      const { Authorization, ...rest } = request.defaults.headers.common;

      request.defaults.headers.common = rest;
      cookie.remove("accessToken");
      cookie.remove("refreshToken");
  
    }

    setUser(null);

    clearUserInfo();
  };

  const setTokens = (response) => {
    const { accessToken, refreshToken } =
      typeof response === "string" ? JSON.parse(response) : response;

    cookie.set("accessToken", accessToken, {
      expires: new Date(decode(accessToken).exp * 1000),
    });
    cookie.set("refreshToken", refreshToken, {
      expires: new Date(decode(refreshToken).exp * 1000),
    });

    request.defaults.headers.common.Authorization = accessToken;

    if (JSON.parse(localStorage.getItem("AUTH_PROVIDER")) !== "krb") {
      setUser({
        ...decode(accessToken),
      });
      setTimeout(loadUserFromCookies, 60000 * 12);
    }
  };

  const auth = async (values) => {
    if (JSON.parse(localStorage.getItem("AUTH_PROVIDER")) === "krb") {
      try {
        const {
          request: { response },
        } = await request.get("/auth");
        if (response) {
            setIsAuth(true)
            // setUser(true)
            const { data: userData } = await request
              .get("/users/personalInformation");
            setUser(JSON.parse(response))
            alert(
              `Вы зашли в систему под учетной записью ${userData?.login} (${userData?.surname} ${userData?.name} ${userData?.patronymic})\n Группа ${userData?.group}`
            );
        }
      } catch (error) {
        if (error.request.status === 401) {
          showUserNotExistErrorNotification(error?.response?.headers?.notfoundeduser);
        } else {
          showUnexpectedErrorNotification();
        }
      }
    } else {
      const {
        request: { response },
      } = await request.post("/auth", values);
  
      if (response) {
        setTokens(response);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuth,
        auth,
        logout,
        loadUserFromCookies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
