/*eslint-disable*/
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import useAuth from "hooks/useAuth";
import BackgroundLogo from "assets/images/logo_background.svg";
import { useTranslation } from "react-i18next";
import InputField from "formAdapters/Input";
import Button from "components/Button";
import { required } from "utils/validate";
import { ReactComponent as Logo } from "assets/images/cyclop_logo.svg";
import request from "utils/request";
import "./styles.css";


const Login = () => {

  const { auth, user } = useAuth();

  const [authProviderType, setAuthProviderType] = useState()

  const fetchAuthProviderType = async () => {
    const { data: authType } = await request.get("/settings/getAuthProviderType");
    setAuthProviderType(authType)
    localStorage.setItem("AUTH_PROVIDER",JSON.stringify(authType));
    if (authType === "krb" && !user) {
      auth();
    }
    return authType;
  }

  useEffect(() => {
    if (authProviderType == null) {
      fetchAuthProviderType()
    }
  }, []);
  const [requestError, setRequestError] = useState(null);

  const onSubmit = async (values) => {
    try {
      await auth(values);
    } catch (error) {
      setRequestError(error.response.status);
      if (error?.response?.status == 401) return { password: " " };

      return {
        login: " ", // t('incorrectlyEnteredData'),
        password: " ", // t('incorrectlyEnteredData'),
      };
    }
  };

  const errorMessage = () => {
    let message = "";

    switch (requestError) {
      case 401: {
        message = "Неправильный пароль";

        break;
      }
      case 400: {
        message = "Пользователь не найден";

        break;
      }
    }

    return message;
  };

  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundSize: "100%",
        backgroundPositionX: "20vw",
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {(authProviderType != undefined) && (authProviderType !== "krb" ? <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <Logo />
            <Field
              type="text"
              name="login"
              placeholder={t("name")}
              className="login-input"
              validate={required}
              component={InputField}
            />
            <Field
              type="password"
              name="password"
              placeholder={t("password")}
              className="login-input"
              validate={required}
              component={InputField}
            />
            <h3 style={{ textAlign: "left", color: "red" }}>
              {errorMessage()}
            </h3>
            <Button
              type="submit"
              text={t("enter")}
              disabled={submitting || pristine}
            />
          </form>
        )}
      /> : <></>)}
    </div>
  );
};

export default Login;
